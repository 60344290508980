//utility function for getting url parameters
function getURLParameter(name) {
    return decodeURI(
        (RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [, null])[1]
    );
}
// extractParameter :: String -> String -> String
const extractParameter = searchString => name => {
    name = String(name).replace(/(\[|\])/g,"\\$1");

    const regex = new RegExp("[\\?&]"+name+"=([^&#]*)");
    const results = regex.exec( searchString );
    return ( results === null ) ?
      "" :
      results[1];
};

// gup :: String -> String
const gup = extractParameter(window.location.href);

window.gup = gup;
window.getURLParameter = getURLParameter;//legacy...
window.extractParameter = extractParameter;