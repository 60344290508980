/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

/* global Cookies gup throttleAfter, throttleDomAfterAsync*/

(function($) {

  //settings
  const scrollEffectDelay = 70;//delay any scroll events by this many MS

  //define common values
  const scrollOffset = 72;
  const scrollTime = 400;

  // cache common selectors (script is in footer, so no need to wait until doc ready)
  const $document = $(document);// eslint-disable-line no-unused-vars
  const $window = $(window);// eslint-disable-line no-unused-vars
  const $html = $('html');// eslint-disable-line no-unused-vars
  const $htmlBody = $('html, body');// eslint-disable-line no-unused-vars
  const $body = $('body');// eslint-disable-line no-unused-vars
  const $topNav = $body.find('.top');// eslint-disable-line no-unused-vars
  const $footer = $body.find('#main-footer');// eslint-disable-line no-unused-vars
  const $menuOverlay = $body.find('.menuOverlay');// eslint-disable-line no-unused-vars
  const $modules = $body.find("[data-module],.module");// eslint-disable-line no-unused-vars
  const $socialNav = $body.find('.social-nav');// eslint-disable-line no-unused-vars
  const $quizBlock = $body.find('#quiz-block');// eslint-disable-line no-unused-vars

  //mutable dimensions
  let topNavHeight = $topNav.height();// eslint-disable-line no-unused-vars
  let footerHeight = $footer.height();// eslint-disable-line no-unused-vars
  let socialHideRanges = getSocialHides();// eslint-disable-line no-unused-vars

  //figure out ~where social hide sections are ahead of time
  function getSocialHides(){
    const hideRanges = [];
    $body.find('.social-hide').each((i,el)=>{
      const { offsetTop, offsetHeight } = el;
      hideRanges.push([offsetTop, offsetHeight+offsetTop])
    });
    return hideRanges;
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Base = {
    // All pages
    common: {
      init: function() {

        // JavaScript to be fired on all pages

        // MOBILE MENU
        function openMenu(e) {
          $menuOverlay.addClass('menu--open');
          e.preventDefault();
          $html.addClass('noScroll');
        }

        function closeMenu(e) {
          $html.removeClass('noScroll');
          $menuOverlay.removeClass('menu--open');
          e.preventDefault();
          e.stopPropagation();
        }

        $body
          .on('click','#btn-menu', openMenu)
          .on('click','#btn-menu-close', closeMenu);

        //close on ESC
        document.addEventListener('keyup', ev => ev.keyCode === 27 && closeMenu(ev) );
        //END MENU

        // NGP
        if ($('.ngp-code.add-callback').length > -1) {
          var ngpCallback = function() {

              $('.ngp-code.add-callback').each(function() {

                  $(this).find('label.EmailAddress').addClass("show");
                  $(this).find('label.EmailAddress input').attr('placeholder','Email Address');

              //     $(this).find('label.PostalCode').addClass("show");
              //     $(this).find('label.PostalCode input').attr('placeholder','Zip Code');

              });
          }

          //strange & worrisome that this is necessary
          $window.bind('load', function(){
            setTimeout(ngpCallback, 500);
            setTimeout(ngpCallback, 1000);
            setTimeout(ngpCallback, 2000);
          });
        }


        $body
          .find('.video-popup').magnificPopup({
              type: 'iframe',
              mainClass: 'mfp-fade',
              removalDelay: 160,
              preloader: false,
              fixedContentPos: false
          });

				$body
          .find('.popup-youtube').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
          });

				$body
          .find('.image-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
          });

        $body.on('click','a.scroll-read-more', function(e) {
            e.preventDefault();
            const scrollTop = $( $(this).attr("href") ).offset().top - scrollOffset
            $htmlBody.animate({ scrollTop }, scrollTime);
        });

        //add class to last module
        $modules.last().addClass('last-module');

        $body.on('click', '.social .social-pop', function(e) {
          if ($window.width() > 967) {
            e.preventDefault();
            window.open(
              this.href,
                '',
              'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=350,width=600'
            );
          }
        });

        var currentID = 1;
        $body.find('.video-share .videoWrapper iframe').each(function(){
            $(this).attr('id', currentID);
            //$(this).appendAttr('src', '&autoplay=0');
            $(this).attr("src", $(this).attr("src") + "&autoplay=0");
            $(this).attr("src", $(this).attr("src") + "&rel=0");
            currentID++;
        });

        var current = 1;
        $body.find('.video-cover').each(function(){
          var findVideo = $('#' + current);
          $(this).click(function() {
              $(this).fadeOut();
              $(findVideo).attr("src", $(findVideo).attr("src").replace("autoplay=0", "autoplay=1"));
          });
          current++;
        });

        // Home Page Video Popup
        $body.find('.pop-over .videoWrapper iframe').each(function(){
            $(this).attr('id', currentID);
            //$(this).appendAttr('src', '&autoplay=0');
            $(this).attr("src", $(this).attr("src") + "&autoplay=1");
            $(this).attr("src", $(this).attr("src") + "&rel=0");
            currentID++;
        });

        $document.on('click touchstart', function(event) {
            if(!$(event.target).closest('.pop-over .videoWrapper iframe').length) {
                if($('.pop-over').is(":visible")) {
                    $('.pop-over').hide();
                    const iFrameSrc = $('.pop-over .videoWrapper iframe').attr('src');
                    if(iFrameSrc){
                      $('.pop-over .videoWrapper iframe').attr('src', iFrameSrc.replace('autoplay=1', 'autoplay=0'));
                    }
                }
            }
        });

        $body.find('.play-button').click(function(event) {
            if(!$(event.target).closest('.pop-over .videoWrapper iframe').length) {
                if($('.pop-over').is(":visible")) {
                    $('.pop-over').hide();
                }
            }
        });

        //alert bar
        const $alertBar = $body.find( ".alert-bar" );

        $body.on( 'click', '.alert-bar__close', function() {
          $alertBar.slideToggle( 'fast' , function() {
            $alertBar.prop( 'hidden', true );
            topNavHeight = $topNav.height();
          } );
          Cookies.set( 'alertbar', 'value', { expires: 7 } );
        } );

        // eslint-disable-next-line no-unused-vars
        const positionStickySupport = function() {
         var el = document.createElement('a'),
             mStyle = el.style;
         mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;";
         return mStyle.position.indexOf('sticky')!==-1;
        }();

        $html.toggleClass('nopositionsticky', !positionStickySupport);

        // addSticky :: Int -> () -> IO (side-effect, adds or removes class)
        // eslint-disable-next-line no-unused-vars
        const addSticky = throttleDomAfterAsync( (e = {}) => {
          const { target = {} } = e;
          let { scrollingElement: { scrollTop } = {} } = target;

          if(!scrollTop){
            scrollTop = $window.scrollTop();
          }

          $topNav.toggleClass('is-sticky', scrollTop >= topNavHeight);
        }, scrollEffectDelay);


        $window.on('scroll', addSticky);
        addSticky();

        function resizeContent() {
            var $height = $window.height() - 72;
            if ($window.width() > 768) {
              if ($window.height() > 750) {
                $quizBlock.addClass('attached').height($height);
              } else {
                $quizBlock.height('auto').removeClass('attached');
              }
          }
        }

        if($quizBlock.length){
          $window.resize(resizeContent);
          resizeContent();
        }

        // Social Hide

        //Icon Detection: Not Used?
        // var $sticky_share = $('.content');

        // function check_if_in_view() { // eslint-disable-line no-unused-vars
        //   var window_height = $window.height();
        //   var window_top_position = $window.scrollTop();
        //   var window_bottom_position = (window_top_position + window_height);

        //   $.each($sticky_share, function() {
        //     var $element = $(this);
        //     var element_height = $element.outerHeight();
        //     var element_top_position = $element.offset().top;
        //     var element_bottom_position = (element_top_position + element_height);

        //     //check to see if this current container is within viewport
        //     if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position - 400)) {
        //       $socialNav.addClass('in-view');
        //     }
        //     // else {

        //     // }
        //   });

        // }

        /* Magical function to detect overlaps*/
        $.fn.overlaps = function(objs) {
          var elems = {targets: [], hits:[]};
          this.each(function() {
            var bounds = $(this).offset();
            bounds.right = bounds.left + $(this).outerWidth();
            bounds.bottom = bounds.top + $(this).outerHeight();

            $(objs).each(function(){
              var obj = this;
              var compare = $(obj).offset();
              compare.right = compare.left + $(obj).outerWidth();
              compare.bottom = compare.top + $(obj).outerHeight();

              if (!(compare.right < bounds.left ||
                    compare.left > bounds.right ||
                    compare.bottom < bounds.top ||
                    compare.top > bounds.bottom)
                 ) {
                  elems.targets.push(this);
                  elems.hits.push(obj);
              }
            });
          });

          return elems;
        };

        if ($('.social-nav').length > -1) {
          const check_overlap = throttleDomAfterAsync(()=>{
              var stickyShare = $socialNav.overlaps('.social-hide');
              $socialNav[stickyShare.hits.length? 'addClass' : 'removeClass']('behind');
              $socialNav[stickyShare.hits.length? 'addClass' : 'removeClass']('animate');
          }, scrollEffectDelay);

          $window.on('scroll resize', check_overlap);
          check_overlap();
          // End Social Hide
        }

        // Print action
        $('.js-print').on('click', function(e) {
          e.preventDefault();
          window.print();
        });

        const checkActiveSections = throttleDomAfterAsync(function() {
          $modules.each(function(i, el) {
            var $el = $(el);
            $el.toggleClass("activeSection", $el.visible(true));
          });
        }, scrollEffectDelay);

        $window.on('scroll', checkActiveSections);

        //LANDING PAGE
        $body
          .on('mouseenter','.scroll', function() {
              $(this).next('.tool').css('opacity', '1');
          })
          .on('mouseleave','.scroll', function() {
              $(this).next('.tool').css('opacity', '0');
          })
          .on('click', '.scroll', function(e) {
              e.preventDefault();
              const scrollAnchor = $(this).attr('data-scroll');
              const scrollTop = $('.module[data-anchor="' + scrollAnchor + '"]').offset().top - 72;

              $htmlBody.animate({ scrollTop }, 500);
          });


        $.fn.visible = function(partial) {
              var $t            = $(this),
                  $w            = $window,
                  viewTop       = $w.scrollTop() - 72,
                  viewBottom    = viewTop + $w.height() - 72,
                  _top          = $t.offset().top - 72,
                  _bottom       = _top + $t.height() - 72,
                  compareTop    = partial === true ? _bottom : _top,
                  compareBottom = partial === true ? _top : _bottom;

            return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

        };

        const $dottedNav = $('.dotted-nav');

        if ($dottedNav.length) {

          //this seems like a lot of work to change one thing white on scroll
          const checkWhiteClass = throttleDomAfterAsync(() => {
              var window_size = $window.height()/2;
              var scrollPosition = $window.scrollTop();
              if (scrollPosition >= window_size) {
                $('.dotted-nav').removeClass('white');
              } else {
                $('.dotted-nav').addClass('white');
              }
          }, scrollEffectDelay);

          $window.on('scroll', checkWhiteClass);

          //activate dotted nav on load
          const s = $('.module.activeSection').attr('id');
          $dottedNav.find(`a#${s}`).addClass('active');

          //event to check which section is active
          const toggleNavColorScroll = throttleDomAfterAsync(() => {
            var s = $('.module.activeSection').attr('id');
            $dottedNav.find('a.active').removeClass('active');
            $dottedNav.find(`a#${s}`).addClass('active');
          }, scrollEffectDelay);

          $window.on('scroll', toggleNavColorScroll);
        }

        $body.on('click','.smooth_scroll', function(e){

            e.preventDefault();
            const $target = $($(this).attr('href'));//usually a #hash selector...

            if($target.length){
              const scrollTop = $target.offset().top - scrollOffset;

              //Animate
              $htmlBody.stop().animate({ scrollTop }, scrollTime);
            }
        });


        //add dynamic sourcing to contribution link
        $('header .contribute-link a').each(function(){
          const $this = $(this);
          const source = Cookies.getJSON('source') || gup('source') || 'website';

          const sourcedUrl = $this.attr('href')
            .replace('website', source)
            .replace('=homepage',`=${source}`);

          $this.attr('href', sourcedUrl);
          // if(source!='website'){
          //  history.replaceState(null, null, "?source="+source);
          // }
        });

        //Search
        const searchType = $body.data('searchtype');

        if(searchType === 'pageOverlay'){

          const openSearch = e => {
            e.preventDefault();
            $('.searchOverlay').addClass('search--open');
            $('.search__input').focus();
            $('html').addClass('noScroll');
          };
          const closeSearch = e => {
            $('html').removeClass('noScroll');
            e.preventDefault();
            $('.searchOverlay').removeClass('search--open');
            $('.search__input').blur();
            $('.search__input').val('');
          }

          $body.on('click','#btn-search', openSearch);
          $body.on('click','#btn-search-close', closeSearch);

          document.addEventListener('keyup', function(e) {
            if( e.keyCode == 27 ) {
              closeSearch(e);
            }
          });

        } else {
          //pulldown search
          $body
            .on('click', "#btn-search, #search-trigger", function(e) {
              e.preventDefault();
              e.stopPropagation();
              $('.top-search .focus-input').focus();
              $body.toggleClass('search-open');
            })
            .on('keyup', '.top-search .focus-input', function() {
              $('.top-search input[type="submit"]').css('opacity', '1');
            });

          document.addEventListener('keyup', function(e) {
            if( e.keyCode == 27 ) {
              $body.removeClass('search-open');
            }
          });

          $document.on('click', function(e) {
              const searchWrap = $(".top-search");
              if(!searchWrap.is(e.target) && searchWrap.has(e.target).length === 0) {
                $body.removeClass('search-open');
              }
          });

        }

        //prevent empty searches on older browsers
        $body.on('submit', '[data-searchform]', (e) => {
          const searchInputValue = $(e.target).find('[name="s"]').val();
          if(searchInputValue === ''){
            e.preventDefault();
          }
        });

      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var namespace = Base;
      funcname = (funcname === undefined) ? 'init' : funcname;
      if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
        UTIL.fire(classnm);
      });
    }
  };

  $document.ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
