window.requestAnimationFrame = window.requestAnimationFrame       ||
               window.webkitRequestAnimationFrame ||
               window.mozRequestAnimationFrame    ||
               (function () {
    var clock = Date.now();

    return function (callback) {
      var currentTime = +new Date();

      if (currentTime - clock > 16) {
        clock = currentTime;
        callback(currentTime);
      } else {
        // recursion wrapped in a setTimeout here
        // to unblock the browser in between loops.
        setTimeout(function () {
          polyfill(callback);
        }, 0);
      }
    };
  })();

window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    var start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        }
      });
    }, 1);
  }

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };

function throttle(fn, threshhold = 250, scope) {
  var last,
      deferTimer;
  return function () {
    var context = scope || this;

    var now = +new Date,
        args = arguments;
    if (last && now < last + threshhold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fn.apply(context, args);
      }, threshhold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

window.throttle = throttle;

//throttle, but only fires on trailing edge, after delay of no similar events
const throttleAfter = (fn, ms = 0, scope) =>{
  let timer = 0;
  return function(...args){
    var context = scope || this;
    clearTimeout (timer);
    timer = setTimeout(()=>fn.apply(context, args), ms);
  };
};

window.throttleAfter = throttleAfter;



//throttle, but only fires on trailing edge, after delay of no similar events
const throttleDomAfterAsync = (fn, ms = 0, scope) =>{
  let timer = 0;
  return function(...args){
    var context = scope || this;
    clearTimeout(timer);
    timer = setTimeout(
      () => requestIdleCallback(
        () => requestAnimationFrame(
          () => fn.apply(context, args)
        ),
        {
          timeout: ms
        }
      ),
      ms
    );
  };
};

window.throttleDomAfterAsync = throttleDomAfterAsync;
